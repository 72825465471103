// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import todo from './todo'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  todo
})

export default rootReducer
